@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* color: #ffff; */
  /* background-color: #222222; */
}

body{
  overflow: hidden;
}

#root{
  height: 100%;
}

.App{
  height: 100vh;
}

.ant-tabs{
  padding: 19px;
  height: 55vh;
}

.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane, .ant-tabs-left .ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane{
  padding-left: 0!important;
}

.ant-list-item-meta-avatar{
  margin-inline-end: 7px;
}

.ant-list-item-meta-content{
  font-weight: bold;
  flex: 2 0;
  /* padding-left: 8px; */
}

/* .ant-list .ant-list-item{
  padding: 5px 15px;
} */
.ant-btn .css-dev-only-do-not-override-26rdvq .ant-btn-circle .ant-btn-default {
  background: white;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:#016D49;
}
.ant-list-item-meta .ant-list-item-meta-avatar{
   margin-inline-end: 0px;
}
.ant-list-item-meta {
  line-height: 2.5;
}
.ant-tabs-left .ant-tabs-mobile .css-dev-only-do-not-override-26rdvq{
  padding: 20px;
}
.ant-list .ant-list-item{
  padding: 8px 0px;
}

.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-avatar{
  margin-inline-end: 5px
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled){
background-color:'#016D49'!important;
}

.ant-radio-checked .ant-radio-inner{
  border-color: #016D49 !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: #016D49  !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #016D49  !important;
}

.ant-radio-checked .ant-radio-inner:focus{
  border-color: #016D49  !important;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: white  !important;
}

/* .ant-form-item {
  border-top: 'none' !important;
  border-left: 'none' !important;
  border-right: 'none' !important;
  border-bottom: 'solid' !important;
  border-color: '#707070' !important;
  border-width: '1px' !important;
  font-size: 18px !important;
} */
.react-tabs {
  display: flex;
  margin-left: 20px;
  width: 87vw;
  /* height: 400px; */
  /* border: 1px solid #3c3e43; */
  /* color: rgb(255, 255, 255); */
  /* background: #1c1d1f; */
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  /* width: 170px; */
  margin: 0;
  padding: 0;
  /* color: rgb(86, 185, 25); */
  background: #F5F5F5;
  border-top-left-radius: 10%;
  /* text-align: center; */
}

.react-tabs__tab {
  height: 60px;
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #0E0E0E;
}

.react-tabs__tab--selected {
  /* background: #FFFFFF; */
  border-color: #1c1d1f;
  /* border-left: 4px solid #6eb800; */
  /* color: rgb(224, 90, 0); */
}

.react-tabs__tab-panel {
  display: none;
  min-width: 50vw;
  overflow: auto;
  white-space: nowrap;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  /* padding-left: 24px; */
}

.react-tabs__tab--selected {
  padding-left: 21px;
}

.panel-content {
  text-align: center;
}

.list-cart-name {
  padding-top: 20px;
  padding-left: 30px;
}

div.scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.tabs-scroll {
  max-height: 50vh; overflow-y: auto;
}
.tabs-scroll-hide {
  overflow-y: hide;
}
/* Track */
::-webkit-scrollbar-thumb {
  /* box-shadow: inset 0 0 5px #FFFFFF;  */
  background: #FFFFFF !important;
  /* border-radius: 10px; */
}
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #FFFFFF;  */
  background: #FFFFFF !important;
  /* border-radius: 10px; */
}